/* ==========================================================================
Projects selector
========================================================================== */
.projects-selector {
  @extend .py-6;
  @extend .py-md-10;

  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.projects-selector-title {
  @extend .pb-3;
}

.projects-selector-text {
  p {
    font-family: $font-family-1;
  }
}

.projects-selector-carousel {
  .owl-dots {
    position: absolute;
    bottom: 15px;
    width: 100%;
    text-align: center;

    .active {
      span {
        @extend .bg-theme-primary;
      }
    }

    .owl-dot {
      span {
        width: 15px;
        height: 15px;
      }

      &:hover {
        span {
          background-color: rgba($theme-primary, 0.7);
        }
      }
    }
  }
}

.projects-selector-link {
  width: 100%;
  height: 350px;
  display: inline-block;
}

.projects-selector-item {
  height: 100%;
}

.projects-selector-img {
  height: 100%;
  object-fit: cover;
}
