/* ==========================================================================
Card image overlay 1
========================================================================== */
.card-image-overlay-1 {
  color: $body-color;

  &::before {
    background-color: rgba($white, 75%);
    border-radius: 0;
  }
}
