/* ==========================================================================
Footer
========================================================================== */
.footer {
  @extend .py-7;

  background-color: $black;
  color: $white;

  @include media-breakpoint-down(md) {
    table {
      margin-top: 65px;

      td {
        display: block;
      }
    }
  }

  a {
    @extend .text-white;
  }

  .form {
    input {
      border: 0;
      border-radius: 0;
    }

    .element,
    label {
      @extend .m-0;
    }

    .submit {
      @extend .mt-1;

      background-color: $theme-primary;
      color: $black;
      width: 100%;

      @include media-breakpoint-only(xs) {
        float: right;
        width: 50%;
      }
    }

    .form-row {
      @extend .mb-1;
    }
  }
}
