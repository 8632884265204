.configurator {
  .container {
    @extend .px-5;
    @extend .py-2;

    background-color: $theme-tertiary;
    color: $white;
  }

  .input {
    p {
      margin-bottom: -10px;
    }

    textarea {
      margin-top: 0;
      margin-bottom: 0;
      min-height: 224px;
    }
  }

  .form-control {
    border: none;
    border-radius: 0;
  }

  .submit {
    background-color: $theme-primary;
    border: none;
    border-radius: 0;
    float: right;

    &:hover {
      background-color: lighten($theme-primary, 10%);
    }
  }
}
