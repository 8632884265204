/* ==========================================================================
Navbar offcanvas brand
========================================================================== */
.navbar-offcanvas-brand {
  @extend .d-block;
  @extend .mb-3;
  @extend .mt-lg-10;

  &:focus {
    outline: 4px solid rgba($theme-primary, 0.25);
    outline-offset: 4px;
  }

  svg {
    height: 40px;
  }
}

.dropdown-menu {
  .navbar-offcanvas-brand {
    @extend .d-lg-none;
    @extend .text-center;
  }
}

.offcanvas-collapse {
  .navbar-offcanvas-brand {
    img {
      height: 70px;
      width: auto;
    }
  }
}
