/* ==========================================================================
Product
========================================================================== */
.product-logo {
  position: relative;
  top: 30px;
  width: 100%;
  z-index: 3;

  img {
    height: 50px;
    width: auto;
  }
}
