/* ==========================================================================
Card default 2
========================================================================== */
.card-default-2 {
  border-width: 5px;

  .card-body {
    @extend .pb-0;
  }

  .card-footer {
    @extend .pb-2;
    @extend .pt-0;

    background-color: transparent;
    border: 0;
  }
}
