/* ==========================================================================
Typography
========================================================================== */
h1,
.h1 {
  @include font-size(58);
  @include letter-spacing(-40);

  font-family: $font-family-2;
  font-weight: 700;
  text-transform: uppercase;

  @include media-breakpoint-up(sm) {
    @include font-size(80);
  }

  @include media-breakpoint-up(md) {
    @include font-size(120);
  }

  @include media-breakpoint-up(xl) {
    @include font-size(160);
  }
}

h2,
.h2 {
  @include font-size(30);
  @include letter-spacing(-40);

  font-family: $font-family-2;
  font-weight: 700;
  text-transform: uppercase;

  @include media-breakpoint-up(sm) {
    @include font-size(45);
  }

  @include media-breakpoint-up(md) {
    @include font-size(58);
  }

  @include media-breakpoint-up(xxl) {
    @include font-size(80);
  }
}

h3,
.h3 {
  @include font-size(25);
  @include letter-spacing(-40);

  font-family: $font-family-2;
  font-weight: 700;
  text-transform: uppercase;

  @include media-breakpoint-up(sm) {
    @include font-size(22);
  }

  @include media-breakpoint-up(md) {
    @include font-size(28);
  }

  @include media-breakpoint-up(xl) {
    @include font-size(40);
  }
}

h4,
.h4 {
  @include font-size(22);
  @include letter-spacing(-40);

  font-family: $font-family-2;
  font-weight: 700;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    @include font-size(24);
  }

  @include media-breakpoint-up(xl) {
    @include font-size(28);
  }
}

h5,
.h5 {
  @include font-size(18px);
  @include letter-spacing(-40);

  font-family: $font-family-2;
  font-weight: 700;
  text-transform: uppercase;

  @include media-breakpoint-up(md) {
    @include font-size(20px);
  }

  @include media-breakpoint-up(xl) {
    @include font-size(24px);
  }
}

h6,
.h6 {
  @include font-size(16px);
  @include letter-spacing(-40);

  font-family: $font-family-2;
  font-weight: 700;
  text-transform: uppercase;
}

p,
label,
td,
.p {
  @include font-size(15);

  font-family: $font-family-1;
  font-weight: 400;

  @include media-breakpoint-up(md) {
    @include font-size(18);
  }
}

small {
  @include font-size(12px);
}
