/* ==========================================================================
Hamburger
========================================================================== */
.hamburger-label {
  @include font-size(10);

  display: block;
  opacity: 1;
  position: relative;
  text-transform: uppercase;
  transition: opacity 0.3s ease-in-out;
  top: -10px;
}

.hamburger {
  @extend .w-100;

  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  padding: 25px 0 6px 0;

  &:focus {
    outline: 4px solid rgba($theme-primary, 0.25);
    outline-offset: -4px;

    .hamburger-label {
      color: $theme-primary;
    }
  }

  &.is-active {
    background-color: $theme-primary;

    .hamburger-label {
      opacity: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 15px 0 6px 0;
    max-height: 50px;
  }
}

.hamburger-inner {
  &::after {
    background-color: transparent;
  }
}
