.navbar-dropdown-filters {
  .filters {
    @extend .text-center;
    @extend .d-flex;

    bottom: $navbar-height-lg;
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    padding: 0 15px;
  }

  @include media-breakpoint-up(lg) {
    .filters {
      bottom: auto;
      padding: 0 10%;
      top: $navbar-height-lg;
    }

    .mat-menu {
      .item--title {
        writing-mode: tb-rl;
        text-orientation: sideways;
        transform: rotate(180deg);
      }
    }
  }

  .filter {
    @include media-breakpoint-only(xs) {
      font-size: 13px;
      padding: 0 7px;
    }

    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
    background-color: $white;
    border: 2px solid $theme-primary;
    color: $theme-primary;
    cursor: pointer;
    line-height: 50px;
    height: 50px;
    transition: all 0.3s ease-in-out;
    z-index: 4;
  }

  input[type=radio] {
    display: none;

    &:checked + .filter {
      background-color: $theme-primary;
      color: $white;
    }
  }

  .filter--data {
    @include media-breakpoint-up(lg) {
      padding: 0 10%;
    }
  }

  .item {
    @extend
      .mb-4,
      .text-center,
      .text-lg-left;

    position: relative;

    &:focus {
      outline: 0;
    }
  }

  .item--list {
    @extend .ml-4;
  }

  .item--title {
    @include font-size(20);
    @include transition();

    cursor: pointer;
    position: relative;
    top: 0;
  }

  .slick-slide {
    &:focus {
      outline: 0;
    }
  }

  .desktop {
    .item--title {
      @include font-size(22);

      position: absolute;
    }

    .link {
      color: $gray-500;
    }
  }

  .list-car {
    position: relative;

    &::after,
    &::before {
      bottom: 0;
      content: "";
      pointer-events: none;
      position: absolute;
      top: 0;
      width: 50px;
      z-index: 1;

      @include media-breakpoint-up(sm) {
        width: 100px;
      }
    }

    &::after {
      background: linear-gradient(to right, rgba($white, 0) 0%, rgba($white, 1) 100%);
      right: 0;
    }

    &::before {
      background: linear-gradient(to right, rgba($white, 1) 0%, rgba($white, 0) 100%);
      left: 0;
    }

    .item {
      @extend .mr-4;
    }

    .item--title {
      @extend .d-none;
    }

    .item--text {
      color: $gray-500;
    }
  }

  .letter-car {
    .slick-current {
      .item {
        &.item--title {
          @include font-size(30);

          color: $theme-primary;
          top: -10px;
        }
      }
    }

    .item--title {
      @extend .mb-3;

      color: $gray-500;
      line-height: 45px;
    }
  }
}
