/* ==========================================================================
Forms
========================================================================== */
.form {
  .input {
    .form-control {
      &:nth-child(n+2) {
        margin-top: 0.5rem;
      }

      &.error {
        border-color: $form-feedback-invalid-color;
        background-image: $form-feedback-icon-invalid;
      }
    }

    textarea {
      resize: none;
    }

    /* ==========================================================================
    Bootstrap-select
    ========================================================================== */
    .bootstrap-select {
      .dropdown-menu {
        padding: 0;

        li {
          &:focus,
          a:focus {
            outline: none;
          }
        }
      }

      .dropdown-toggle {
        @extend .form-control;

        margin-top: 0 !important;

        &:hover,
        &:focus {
          @extend :focus;

          outline: none !important;
        }
      }
    }

    &.has-success,
    &.has-error {
      .bootstrap-select {
        .dropdown-toggle {
          background-repeat: no-repeat;
          background-position: center right calc(#{$input-height-inner} / 4);
          background-size: calc(#{$input-height-inner} / 2) calc(#{$input-height-inner} / 2);

          &::after {
            display: none;
          }
        }
      }
    }

    /* stylelint-disable */
    &.has-success > .bootstrap-select > .dropdown-toggle {
      border-color: $form-feedback-valid-color;
      background-image: $form-feedback-icon-valid;

      &:hover {
        box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-valid-color, 0.25);
      }
    }

    &.has-error > .bootstrap-select > .dropdown-toggle {
      border-color: $form-feedback-invalid-color;
      background-image: $form-feedback-icon-invalid;

      &:hover {
        box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, 0.25);
      }
    }
    /* stylelint-enable */
  }

  .form-row {
    hr {
      margin-top: 0.5rem;
    }
  }
}

/* ==========================================================================
Formidable
========================================================================== */
.formidable {
  .hide {
    display: none;
  }

  div[class^="captcha"] {
    @extend .d-none;
  }

  .input {
    .file_upload {
      .dz-message {
        @extend .form-control;

        color: inherit;
        cursor: pointer;
        height: auto;
        text-align: center;

        &:hover {
          @extend :focus;
        }
      }

      .fallback {
        input[type="file"] {
          display: none;
        }
      }

      .error,
      .dz-started {
        .dz-message {
          padding-right: $input-height-inner;
          background-repeat: no-repeat;
          background-position: center right calc(#{$input-height-inner} / 4);
          background-size: calc(#{$input-height-inner} / 2) calc(#{$input-height-inner} / 2);
        }
      }

      /* stylelint-disable */
      .error {
        .dz-message {
          border-color: $form-feedback-invalid-color;
          background-image: $form-feedback-icon-invalid;

          &:hover {
            box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, 0.25);
          }
        }
      }

      .dz-started {
        .dz-message {
          border-color: $form-feedback-valid-color;
          background-image: $form-feedback-icon-valid;

          &:hover {
            box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-valid-color, 0.25);
          }
        }
      }
      /* stylelint-enable */
      .dz-image,
      .dz-success-mark,
      .dz-error-mark,
      .dz-progress {
        display: none;
      }

      .dz-details,
      .dz-size,
      .dz-filename,
      .dz-remove {
        @extend .d-inline;
      }

      .dz-preview {
        @extend .mt-3;
      }

      .dz-filename {
        @extend .font-weight-bold;
      }

      .dz-remove {
        @extend .ml-1;
      }
    }
  }

  .input-checkbox {
    .custom-control {
      @extend .custom-checkbox;
    }

    input[type="checkbox"] {
      @extend .custom-control-input;
    }

    .custom-control-label {
      p {
        margin-bottom: 0;
      }
    }

    &.has-error {
      .error {
        @extend .d-block;
        @extend .mt-3;
      }
    }
  }

  .input-radio {
    .custom-control {
      @extend .custom-radio;
    }

    input[type="radio"] {
      @extend .custom-control-input;
    }
  }

  .please_wait_loader {
    display: none;
    margin-left: 0.5rem;
  }

  .submit {
    @extend .mt-1;
    @extend .border-0;
    @extend .rounded-0;

    background-color: $theme-primary;
    color: $black;
    width: 100%;

    @include media-breakpoint-only(xs) {
      float: right;
      width: 50%;
    }
  }

  @include media-breakpoint-up(sm) {
    .submit {
      width: auto;
    }
  }
}
