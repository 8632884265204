/* ==========================================================================
Navbar
========================================================================== */
.navbar {
  @extend .p-0;

  bottom: 0;
  // height: $navbar-height-xs;
  left: 0;
  position: fixed;
  right: 0;
  z-index: $zindex-fixed;
}

@include media-breakpoint-up(lg) {
  .navbar {
    bottom: auto;
    top: 0;
  }
}

@include media-breakpoint-down(md) {
  .container {
    max-width: 100%;
  }
}

.navbar--desktop {
  @extend .d-none;
  @extend .d-lg-block;

  background-color: $white;
  position: fixed;
  top: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  z-index: 3;
}

/* ==========================================================================
Navbar-nav
========================================================================== */
.navbar-nav {
  @extend .d-inline-block;

  .nav-link {
    @include font-size(25);

    color: $white;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;

    i,
    svg {
      @extend .mr-1;
    }
  }

  .dropdown {
    .link-line {
      background: $theme-primary;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 3px;
      width: 100px;
    }
  }

  .nav-item {
    &.nav-home {
      display: none;
    }

    &.active {
      border-bottom: 3px solid #00a4db;
      width: 100px;
    }
  }

  .nav-offerte {
    @extend .mt-5;

    .nav-link {
      @extend .p-1;
      @extend .px-5;

      @include font-size(20);

      display: inline-block;
      background-color: $black;
    }
  }

  .nav-search {
    @extend
      .mt-5,
      .mb-5;

    i,
    svg {
      @extend .d-block;

      line-height: 30px;
      position: relative;
      right: auto;
      top: auto;
      transform: none;
    }
  }

  .desktop--search {
    @include font-size(20);

    background-color: transparent;
    border: none;
    border-bottom: 2px solid $white;
    color: white;
    width: 100%;

    &::placeholder {
      color: $white;
    }

    &:focus {
      outline: none;
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar-nav {
    .nav-link {
      @include font-size(18);
    }
  }
}

@include media-breakpoint-up(xl) {
  .navbar-nav {
    .nav-link {
      @include font-size(20);
    }
  }
}

@include media-breakpoint-up(xxl) {
  .navbar-nav {
    .nav-link {
      @include font-size(30);
    }
  }
}
