.faq--block {
  @extend .my-5;

  .faq--button {
    @extend .p-2;
    @extend .mb-1;

    background-color: $theme-tertiary;
    color: $white;
    cursor: pointer;

    &:hover {
      background-color: lighten($theme-tertiary, 10%);
    }

    i,
    svg {
      @extend .mx-1;
    }
  }

  .faq--text {
    display: none;

    p {
      @extend .p-2;
      @extend .pb-4;
      @extend .m-0;
    }
  }

  td {
    width: 100%;
  }
}
