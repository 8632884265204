/* ==========================================================================
Card image overlay 2
========================================================================== */
$card-img-overlay: 30px;

.card-image-overlay-2 {
  @extend
    .mb-2,
    .mb-md-0;

  @include transition();

  bottom: 0;
  height: 400px;
  position: relative;

  &::before {
    @include transition();

    background-color: rgba(6, 60, 125, 0.8);
    border-radius: 0;
    opacity: 0;
  }

  .card-img-overlay {
    @extend .p-0;

    bottom: $card-img-overlay;
    left: $card-img-overlay;
    overflow: hidden;
    position: absolute;
    top: $card-img-overlay;
    right: $card-img-overlay;
  }

  .card-img {
    border-radius: 0;
    filter: grayscale(100%);
  }

  .card-link {
    a {
      @include font-size(20);

      font-family: $font-family-2;
      font-weight: 500;
    }

    p {
      font-weight: 900;
    }
  }

  .card-footer {
    @extend .p-0;

    @include transition();

    background: transparent;
    border: 0;
    bottom: -30px;
    position: absolute;
  }

  .card-title {
    @extend .mb-0;

    @include font-size(40);
    @include letter-spacing(-20);
    @include transition();

    line-height: 1;
    text-transform: uppercase;
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    .card-footer {
      bottom: 0;
    }
  }
}

@include media-breakpoint-up(lg) {
  .card-image-overlay-2 {
    &:hover {
      bottom: 40px;
    }

    .card-title {
      @include font-size(30);
    }
  }
}

/* ==========================================================================
Showcase links
========================================================================== */
.showcase--links-light {
  .card-image-overlay-2 {
    .card-link {
      a {
        color: $white;
      }
    }

    .card-title {
      color: $white;
      text-shadow: 0 0 10px rgba($black, 0.3);
    }
  }
}

.showcase--links-dark {
  .card-image-overlay-2 {
    .card-link {
      a {
        color: $black;
      }
    }

    .card-title {
      color: $black;
    }

    &:hover {
      .card-link {
        a {
          color: $white;
        }
      }

      .card-title {
        color: $white;
      }
    }
  }
}

.showcase--links {
  @extend
    .pb-0,
    .pb-md-3,
    .pb-lg-5,
    .pt-2,
    .pt-md-3,
    .pt-lg-5;

  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;

  .container {
    @extend
      .px-2,
      .px-md-3;
  }
}

@include media-breakpoint-up(lg) {
  .showcase--links {
    .container {
      max-width: 1200px;
    }

    .row {
      min-height: calc(100vh - 100px);
    }
  }
}

@include media-breakpoint-up(xxl) {
  .showcase--links {
    .col-xxl-custom {
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
}
