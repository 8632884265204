/* ==========================================================================
Navbar dropdown menu close
========================================================================== */
$dropdown-menu-close-distance: 15px;
$dropdown-menu-close-size: 50px;

.dropdown-menu-close {
  @extend .d-lg-none;

  @include font-size(30);

  background-color: $theme-primary;
  bottom: $dropdown-menu-close-distance;
  content: "\f0d9";
  color: $white;
  cursor: pointer;
  /* stylelint-disable */
  font-family: "Font Awesome 5 Pro";
  /* stylelint-enable */
  font-weight: 400;
  left: $dropdown-menu-close-distance;
  line-height: $dropdown-menu-close-size;
  height: $dropdown-menu-close-size;
  position: absolute;
  text-align: center;
  text-indent: -2px;
  transition: all 0.3s ease-in-out;
  width: $dropdown-menu-close-size;
  z-index: 4;

  &:focus,
  &:hover {
    background-color: $white;
    color: $theme-primary;
  }
}
