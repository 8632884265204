/* ==========================================================================
Card default
========================================================================== */
.card-default {
  @extend .mb-3;

  .card-buttons {
    font-size: 0;

    .btn {
      width: 100%;
    }

    .btn + .btn {
      @extend .ml-sm-1;
      @extend .mt-1;
      @extend .mt-sm-0;
    }
  }
}

@include media-breakpoint-up(sm) {
  .card-default {
    .card-buttons {
      .btn {
        width: auto;
      }
    }
  }
}
