/* ==========================================================================
Offcanvas
========================================================================== */
html,
body {
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

@include media-breakpoint-down(md) {
  .offcanvas-collapse {
    background-color: $gray-600;
    bottom: 0;
    left: 100%;
    width: 100%;
    overflow-y: auto;
    padding-right: 1rem;
    padding-left: 1rem;
    position: fixed;
    top: $navbar-height;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    transition-property: left, visibility;
    visibility: hidden;

    &.open {
      left: 0;
      visibility: visible;
    }
  }
}
