/* ==========================================================================
Hero
========================================================================== */
.hero {
  position: relative;
}

/* ==========================================================================
Hero image
========================================================================== */
.hero--image {
  @extend .img-fluid;

  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
}

/* ==========================================================================
Hero inner
========================================================================== */
.hero--inner {
  height: calc(100vh - 70px);
  min-height: 400px;
  position: relative;

  @include media-breakpoint-up(lg) {
    height: 100vh;
  }
}

.hero--small .hero--inner {
  height: 400px;
}

/* ==========================================================================
Hero overlay
========================================================================== */
.hero--overlay {
  position: absolute;
  width: 100%;
  height: 100%;

  &.b-radial {
    background: -webkit-radial-gradient(transparent, #000);
    background: -o-radial-gradient(transparent, #000);
    background: radial-gradient(transparent, #000);
  }

  &.b-left {
    background: -webkit-gradient(linear, left top, right top, from(#000), color-stop(25%, #000), to(transparent));
    background: -webkit-linear-gradient(left, #000, #000 25%, transparent);
    background: -o-linear-gradient(left, #000 0, #000 25%, transparent 100%);
    background: linear-gradient(90deg, #000 0, #000 25%, transparent);
  }

  &.b-right {
    background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(75%, #000), to(#000));
    background: -webkit-linear-gradient(left, transparent, #000 75%, #000);
    background: -o-linear-gradient(left, transparent 0, #000 75%, #000 100%);
    background: linear-gradient(90deg, transparent 0, #000 75%, #000);
  }

  .container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

/* ==========================================================================
Hero arrow
========================================================================== */
.hero--arrow {
  @include font-size(20px);

  background-color: $primary;
  border-radius: 10px;
  bottom: 20px;
  color: $white;
  display: block;
  height: $hero-arrow-size;
  line-height: $hero-arrow-size;
  left: 50%;
  position: absolute;
  text-align: center;
  transform: translateX(-50%);
  width: $hero-arrow-size;
}

/* ==========================================================================
Hero buttons
========================================================================== */
.hero--buttons {
  font-size: 0;

  @include media-breakpoint-only(xs) {
    .btn {
      width: 100%;
    }
  }

  .btn {
    @extend .mb-1;
    @extend .mb-sm-0;

    min-width: 300px;
  }

  .btn-secondary + .btn-primary {
    @extend .ml-sm-3;
  }
}

/* ==========================================================================
Hero logo
========================================================================== */
.hero--logo {
  position: absolute;
  top: 30px;
  left: 30px;
  z-index: 1;

  img {
    height: 70px;
    width: auto;
  }
}

/* ==========================================================================
Hero title
========================================================================== */
.hero--title {
  h1 {
    @extend .m-0;

    @include font-size(80);

    line-height: 0.85;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      @include font-size(170);
    }

    @include media-breakpoint-up(lg) {
      @include font-size(200);
    }

    @include media-breakpoint-up(xl) {
      @include font-size(230);
    }
  }
}

/* ==========================================================================
Hero subtitle
========================================================================== */
.hero--subtitle {
  h2 {
    @extend .m-0;

    @include font-size(40);

    line-height: 1;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      @include font-size(70);
    }

    @include media-breakpoint-up(lg) {
      @include font-size(100);
    }

    @include media-breakpoint-up(xl) {
      @include font-size(130);
    }
  }
}

/* ==========================================================================
Hero text
========================================================================== */
.hero--text {
  p {
    @extend .m-0;

    @include font-size(25);
    @include letter-spacing(-40);

    font-family: $font-family-2;
    line-height: 1;
    text-transform: uppercase;

    @include media-breakpoint-up(sm) {
      @include font-size(45);
    }
  }
}

/* ==========================================================================
Hero light
========================================================================== */
.hero--light {
  .hero--title,
  .hero--subtitle,
  .hero--text {
    color: $white;
  }
}

.hero--video-mute-btn {
  position: absolute;
  right: 0;
  bottom: 20px;

  .fa-volume-slash,
  .fa-volume-up {
    color: $white;
  }
}
