.modal {
  color: black;

  .btn-success {
    background-color: $theme-primary;
    border: none;
    border-radius: 0;
    float: right;

    &:hover {
      background-color: lighten($theme-primary, 10%);
    }

    &:focus {
      box-shadow: none;
    }
  }

  .btn-success:not(:disabled):not(.disabled):active {
    background-color: $theme-primary;
    box-shadow: none;
  }
}
