.form-group.materiaalkeuze {
  width: 470px;

  [data-id="materiaalkeuze"] {
    background-color: $black !important;
    color: $white !important;
  }

  .dropdown-item.active {
    background-color: $theme-primary;
  }

  .dropdown-toggle::after {
    margin-left: -10px;
    font-size: 20px;
    vertical-align: 0.15em;
  }

  .dropdown-menu,
  .input button {
    border-radius: 0 !important;
  }
}

.certificate-block {
  h2 {
    @extend .p;

    font-weight: bold;
    text-transform: none;
  }
}
