.contact-bar {
  .row {
    @extend .px-sm-5;
    @extend .px-md-10;
    @extend .px-xl-5;
    @extend .px-xxl-10;

    background-color: $theme-primary;
    height: 300px;
  }
}
