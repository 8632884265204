#searchResults {
  @extend .mt-5;
}

.search--page {
  .btn,
  .form-control {
    border-radius: 0;
  }

  .search--container {
    position: relative;
  }

  .desktop--search {
    height: 51px;
  }

  .btn {
    padding-bottom: 9px;
    padding-top: 10px;
    top: 0;
    position: absolute;
  }

  .form-control {
    background-color: $black;
    background-clip: unset;
    border-color: $black;
    color: $white;
    width: calc(100% - 54px);
  }
}

/* ==========================================================================
Search modal
========================================================================== */
.search--modal {
  .close {
    @extend .px-2;
    @extend .py-1;

    @include font-size(40px);

    color: $white;
    opacity: 1;
    position: absolute;
    right: 0;
    text-shadow: none;
    top: 0;
    transition: color 0.15s ease-in-out;

    &:focus,
    &:hover {
      color: $theme-primary;
    }
  }

  .modal-content {
    @extend .m-2;
    @extend .m-sm-0;

    background-color: transparent;
    border-radius: 0;
    border: 0;
  }

  .modal-header {
    @extend .p-0;
    @extend .pb-1;

    border: 0;

    .modal-title {
      color: $white;
    }
  }

  .modal-body {
    @extend .p-0;

    .form-inline {
      .btn,
      .form-control {
        border-radius: 0;
      }

      .btn {
        padding-bottom: 9px;
        padding-top: 10px;
      }

      .form-control {
        background-color: $black;
        background-clip: unset;
        border-color: $black;
        color: $white;
        width: calc(100% - 54px);
      }
    }
  }
}
