/* ==========================================================================
Navbar offcanvas
========================================================================== */
html,
body {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden; /* Prevent scroll on narrow devices */
}

.offcanvas-open {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
}

.offcanvas-collapse {
  @extend .pt-5;
  @extend .pt-lg-0;
  @extend .px-3;
  @extend .px-lg-6;
  @extend .px-xl-8;
  @extend .px-xxl-6;
}

@include media-breakpoint-down(xxl) {
  .offcanvas-collapse {
    -webkit-overflow-scrolling: touch;
    background-color: $black;
    height: 100vh;
    left: 100%;
    overflow-y: auto;
    position: absolute;
    top: 0;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    transition-property: left, visibility;
    visibility: hidden;
    width: 33%;

    &.open {
      left: 67%;
      visibility: visible;
    }
  }
}

@include media-breakpoint-down(md) {
  .offcanvas-collapse {
    position: fixed;
    width: 100%;

    &.open {
      left: 0;
      visibility: visible;
    }
  }
}
