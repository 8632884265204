/* ==========================================================================
Navbar-brand
========================================================================== */
.navbar-brand {
  @extend .d-none;
  @extend .d-lg-block;
  @extend .m-0;
  @extend .p-0;
}

@include media-breakpoint-up(lg) {
  .navbar-brand {
    position: relative;

    img {
      height: 50px;
    }

    .navbar-brand-default {
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    }

    .navbar-brand-scroll {
      left: 0;
      opacity: 0;
      position: absolute;
      transition: opacity 0.3s ease-in-out;
    }
  }
}

.navbar.scroll {
  .navbar-brand-default {
    opacity: 0;
  }

  .navbar-brand-scroll {
    opacity: 1;
  }
}
