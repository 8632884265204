.rslides {
  height: 450px;

  li {
    height: 450px;
  }

  img {
    max-height: 450px;
    object-fit: cover;
  }
}

.product-slider {
  .owl-nav {
    position: absolute;
    top: 33%;
    left: 0;
    right: 0;
  }

  .owl-prev {
    left: -30px;
  }

  .owl-next {
    right: -30px;
  }

  .owl-prev,
  .owl-next {
    position: absolute;

    &:hover {
      background: transparent !important;
      color: $theme-primary !important;
    }
  }

  .slider-text {
    position: absolute;
    padding-left: 20px;
    bottom: 0;

    p {
      color: $theme-primary;
      font-weight: 900;
    }
  }

  .slider-overlay {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;

    &:hover {
      background-color: $theme-primary;

      p {
        color: $black;
      }
    }
  }

  .title {
    color: $white;
  }

  .owl-dots {
    @extend .mt-1;
  }

  .owl-dots .owl-dot span {
    background-color: $white;
    border-radius: 0;
    border: 1px solid $black;

    &:hover {
      background-color: $theme-tertiary;
    }
  }

  .owl-dots .owl-dot.active span {
    background-color: $theme-primary;
    border: none;
  }
}

.project-slider {
  .rslides > li {
    height: 500px;
  }

  .rslides_tabs {
    position: absolute;
    bottom: 0;
    margin-bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
  }

  .rslides_here {
    a {
      background: $theme-primary !important;
    }
  }
}
