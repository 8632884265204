/* ==========================================================================
banner
========================================================================== */
.banner {
  position: relative;

  .vertical-center {
    display: flex;
    align-items: center;
  }
}

/* ==========================================================================
banner image
========================================================================== */
.banner--image {
  @extend .img-fluid;

  background-size: cover;
  background-position-x: center;
  height: 100%;
  max-height: 800px;
  object-fit: cover;
  object-position: 50% 50%;
  width: 100%;
}

.banner-overlay-theme-primary {
  position: relative;

  &::after {
    @extend .w-100;

    bottom: 0;
    background: rgba($theme-tertiary, 0.7);
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }
}

/* ==========================================================================
banner inner
========================================================================== */
.banner--inner {
  @extend .py-5;

  position: relative;
  z-index: 3;

  @include media-breakpoint-up(md) {
    min-height: 700px;
  }

  &.b-radial {
    background: -webkit-radial-gradient(transparent, #000);
    background: -o-radial-gradient(transparent, #000);
    background: radial-gradient(transparent, #000);
  }

  &.b-left {
    background: -webkit-gradient(linear, left top, right top, from(#000), color-stop(25%, #000), to(transparent));
    background: -webkit-linear-gradient(left, #000, #000 25%, transparent);
    background: -o-linear-gradient(left, #000 0, #000 25%, transparent 100%);
    background: linear-gradient(90deg, #000 0, #000 25%, transparent);
  }

  &.b-right {
    background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(75%, #000), to(#000));
    background: -webkit-linear-gradient(left, transparent, #000 75%, #000);
    background: -o-linear-gradient(left, transparent 0, #000 75%, #000 100%);
    background: linear-gradient(90deg, transparent 0, #000 75%, #000);
  }
}

.banner--text {
  h2 {
    line-height: 0.9;

    @include media-breakpoint-up(xl) {
      @include font-size(100);
    }
  }

  .btn {
    @extend .mt-3;

    min-width: 250px;
    border-radius: 0;
    padding: 0.6125rem 0;
    text-transform: uppercase;
    font-family: $font-family-2;
  }
}

/* ==========================================================================
banner logo
========================================================================== */
.banner--logo {
  @extend .mb-2;
}

/* ==========================================================================
banner rating text
========================================================================== */
.rating--block {
  max-height: 160px;
  max-width: 360px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: white;
  margin: 50px 0 50px 0;

  @include media-breakpoint-up(lg) {
    position: absolute;
    top: 50%;
    margin: -15% 0 0 20%;
  }
}

.rating--stars {
  @extend .mt-2;

  .fa-stack {
    @include font-size(12);

    @include media-breakpoint-up(sm) {
      @include font-size(15);
    }
  }

  .fa-half {
    position: relative;

    i.fa-square-full {
      width: 40%;
      overflow: hidden;
    }

    i.fa-star-half {
      width: 51%;
      left: 6px;
      overflow: hidden;
    }
  }
}

.rating--text {
  @include font-size(12);

  color: $gray-500;

  @include media-breakpoint-up(sm) {
    @include font-size(15);
  }
}

.rating--number {
  @include font-size(50);

  color: $theme-primary;
  font-weight: bold;

  @include media-breakpoint-up(sm) {
    @include font-size(60);
  }
}

/* ==========================================================================
banner light
========================================================================== */
.banner--light {
  .banner--title,
  .banner--text {
    color: $white;
  }
}

/* ==========================================================================
banner cards
========================================================================== */

.b-radial-before::before {
  background: -webkit-radial-gradient(transparent, #000);
  background: -o-radial-gradient(transparent, #000);
  background: radial-gradient(transparent, #000);
}

.b-left-before::before {
  background: -webkit-gradient(linear, left top, right top, from(#000), color-stop(25%, #000), to(transparent));
  background: -webkit-linear-gradient(left, #000, #000 25%, transparent);
  background: -o-linear-gradient(left, #000 0, #000 25%, transparent 100%);
  background: linear-gradient(90deg, #000 0, #000 25%, transparent);
}

.b-right-before::before {
  background: -webkit-gradient(linear, left top, right top, from(transparent), color-stop(75%, #000), to(#000));
  background: -webkit-linear-gradient(left, transparent, #000 75%, #000);
  background: -o-linear-gradient(left, transparent 0, #000 75%, #000 100%);
  background: linear-gradient(90deg, transparent 0, #000 75%, #000);
}
