/* ==========================================================================
Social media
========================================================================== */
.ccm-block-social-links {
  @include font-size(30);

  i,
  svg {
    @include transition();
  }

  a {
    &:focus,
    &:hover {
      i,
      svg {
        color: $theme-primary;
      }
    }
  }
}
