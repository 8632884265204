/* ==========================================================================
Colors
========================================================================== */
$white:                                 #fff;       // Default BS4
$gray-100:                              #f8f9fa;    // Default BS4
$gray-200:                              #e9ecef;    // Default BS4
$gray-300:                              #dee2e6;    // Default BS4
$gray-400:                              #ced4da;    // Default BS4
$gray-500:                              #adb5bd;    // Default BS4
$gray-600:                              #6c757d;    // Default BS4
$gray-700:                              #495057;    // Default BS4
$gray-800:                              #343a40;    // Default BS4
$gray-900:                              #212529;    // Default BS4
$black:                                 #000;       // Default BS4
$soft-black:                            #222;

$blue:                                  #007bff;    // Default BS4
$indigo:                                #6610f2;    // Default BS4
$purple:                                #6f42c1;    // Default BS4
$pink:                                  #e83e8c;    // Default BS4
$red:                                   #dc3545;    // Default BS4
$orange:                                #fd7e14;    // Default BS4
$yellow:                                #ffc107;    // Default BS4
$green:                                 #28a745;    // Default BS4
$teal:                                  #20c997;    // Default BS4
$cyan:                                  #17a2b8;    // Default BS4

$theme-primary:                         #00a4db;    // Custom Theme Color
$theme-secondary:                       $soft-black;// Custom Theme Color
$theme-tertiary:                        #1d70c5;      // Custom Theme Color

$danger:                                $red;       // Default BS4
$dark:                                  $gray-800;  // Default BS4
$info:                                  $cyan;      // Default BS4
$light:                                 $gray-100;  // Default BS4
$primary:                               $blue;      // Default BS4
$secondary:                             $gray-600;  // Default BS4
$success:                               $green;     // Default BS4
$warning:                               $yellow;    // Default BS4

$theme-colors: ();
$theme-colors: map-merge(
  (
    "danger":                           $danger,
    "dark":                             $dark,
    "info":                             $info,
    "light":                            $light,
    "primary":                          $primary,
    "secondary":                        $secondary,
    "success":                          $success,
    "warning":                          $warning,
    "theme-primary":                    $theme-primary,
    "theme-secondary":                  $theme-secondary,
    "theme-tertiary":                   $theme-tertiary
  ),
  $theme-colors
);

/* ==========================================================================
Body
========================================================================== */
// $body-bg:                               $theme-gray-100;
// $body-color:                            $white;

/* ==========================================================================
Buttons
========================================================================== */
// $btn-border-radius:                     0;
// $btn-border-radius-lg:                  $btn-border-radius;
// $btn-border-radius-sm:                  $btn-border-radius;
// $btn-border-width:                      0;
// $btn-font-weight:                       700;
// $btn-min-width:                         200px;
// $btn-padding-y:                         0.8125rem;
// $btn-padding-x:                         1.25rem;

/* ==========================================================================
Cards
========================================================================== */
// $card-border-radius:                    0;

/* ==========================================================================
Concrete5
========================================================================== */
$ccm-toolbar-height:                    48px;

/* ==========================================================================
Header
========================================================================== */
$navbar-height:                         50px;
$navbar-height-xs:                      70px;
$navbar-height-lg:                      90px;

/* ==========================================================================
Fonts
========================================================================== */
$font-family-1:                         "Lato", sans-serif;
$font-family-2:                         "Oswald", sans-serif;
$font-family-base:                      $font-family-1;

/* ==========================================================================
Footer
========================================================================== */
$footer-height:                         60px;

/* ==========================================================================
Grid breakpoints
========================================================================== */
$grid-breakpoints: ();
$grid-breakpoints: map-merge(
  (
    xs:                                 0,
    sm:                                 576px,
    md:                                 768px,
    lg:                                 992px,
    xl:                                 1200px,
    xxl:                                1430px
  ),
  $grid-breakpoints
);

/* ==========================================================================
Grid containers
========================================================================== */
$container-max-widths: ();
$container-max-widths: map-merge(
  (
    xs:                                 480px,
    sm:                                 540px,
    md:                                 720px,
    lg:                                 960px,
    xl:                                 1140px,
    xxl:                                1430px
  ),
  $container-max-widths
);

/* ==========================================================================
Hamburgers (https://github.com/jonsuh/hamburgers)
========================================================================== */
$hamburger-hover-opacity: 1;
$hamburger-active-hover-opacity: $hamburger-hover-opacity;
$hamburger-active-layer-color: $white;
$hamburger-layer-border-radius: 0;
$hamburger-layer-color: $black;
$hamburger-layer-height: 3px;
$hamburger-layer-spacing: 4px;
$hamburger-layer-width: 30px;
$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-types: (spring, arrow-r);

/* ==========================================================================
Hero
========================================================================== */
$hero-arrow-size:                       50px;
$hero-height-xs:                        400px;
$hero-height-md:                        500px;
$hero-height-xl:                        600px;

/* ==========================================================================
Spacing
========================================================================== */
$spacer: 0.625rem;
$spacers: ();
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0:                                  0,
    1:                                  $spacer,          // 10px
    15:                                 ($spacer * 1.5),  // 15px
    2:                                  ($spacer * 2),    // 20px
    25:                                 ($spacer * 2.5),  // 25px
    3:                                  ($spacer * 3),    // 30px
    35:                                 ($spacer * 3.5),  // 35px
    4:                                  ($spacer * 4),    // 40px
    45:                                 ($spacer * 4.5),  // 45px
    5:                                  ($spacer * 5),    // 50px
    55:                                 ($spacer * 5.5),  // 55px
    6:                                  ($spacer * 6),    // 60px
    65:                                 ($spacer * 6.5),  // 65px
    7:                                  ($spacer * 7),    // 70px
    75:                                 ($spacer * 7.5),  // 75px
    8:                                  ($spacer * 8),    // 80px
    85:                                 ($spacer * 8.5),  // 85px
    9:                                  ($spacer * 9),    // 90px
    95:                                 ($spacer * 9.5),  // 95px
    10:                                 ($spacer * 10)
  ),
  $spacers
);

/* ==========================================================================
Z-index
========================================================================== */
$zindex-dropdown:                       500;
$zindex-sticky:                         520;
$zindex-fixed:                          530;
$zindex-modal-backdrop:                 540;
$zindex-modal:                          550;
$zindex-popover:                        560;
$zindex-tooltip:                        570;
