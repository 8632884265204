/* ==========================================================================
Card image overlay light
========================================================================== */
.card-image-overlay-light {
  @extend .py-6;
  @extend .py-md-10;

  background-color: $white;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;

  .card,
  .card-img,
  .card-image-overlay::before {
    border-radius: 0;
  }

  .card-image-title {
    @extend .pb-1;

    line-height: 1;
  }

  .card-image-text {
    p {
      font-weight: bold;
      line-height: 1.7;
    }
  }

  .card-image-text,
  .card-text {
    p {
      font-family: $font-family-1;
    }
  }

  .card-image-overlay {
    @extend .py-2;

    color: $white;
    background-color: $black !important;

    &::before {
      background-color: transparent !important;
    }
  }

  .card-text {
    @extend .pt-3;

    p {
      line-height: 1.7;

      @include font-size(15);
    }
  }
}
