.card-image-overlay-4 {
  min-height: 0;
  min-width: 0;

  &::before {
    background-color: rgba($white, 0%);
    border-radius: 0;
  }

  .card-title {
    @extend .m-0;

    color: $black;
  }

  .card-description {
    color: $black;
  }

  .card-img-overlay {
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    right: 0;

    .card-link {
      a {
        @extend .d-none;

        color: $theme-primary;
      }

      p {
        font-weight: 900;
      }
    }

    &:hover {
      .card-link {
        a {
          @extend .d-block;
        }
      }
    }
  }

  .card-footer {
    @extend .p-0;

    border: 0;
    background: transparent;
    position: absolute;
    bottom: 0;
  }
}
