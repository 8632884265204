.ccm-block-page-list-wrapper {
  .plp_form_loading {
    color: $theme-primary;
    display: none;
    height: 3rem;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    width: 3rem;
    z-index: 2;
  }
}
