/* ==========================================================================
Card default 1
========================================================================== */
.card-default-1 {
  border: 0;

  .card-img-top {
    border-radius: $card-border-radius;
  }

  .card-body {
    @extend .p-0;
    @extend .pt-1;
  }

  .card-footer {
    @extend .p-0;

    background-color: transparent;
    border: 0;
  }
}
