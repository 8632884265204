/* ==========================================================================
Navbar mobile
========================================================================== */
.navbar--mobile {
  @extend .d-flex;
  @extend .d-lg-none;
  @extend .h-100;
  @extend .w-100;

  background-color: $white;
  box-shadow: 0 -1px 5px 0 rgba($body-color, 0.15);
  position: relative;
  z-index: 3;
}

.navbar--mobile-item {
  @extend .w-25;

  position: relative;
  text-align: center;

  &::after {
    background-color: rgba($gray-300, 0.05);
    bottom: 6px;
    content: "";
    position: absolute;
    right: 0;
    top: 6px;
    width: 1px;
    z-index: 2;
  }

  &:last-child {
    &::after {
      display: none;
    }
  }
}

.navbar--mobile-link {
  @extend .d-block;

  color: $body-color;
  padding: 15px 0 16px;

  i,
  svg {
    @include font-size(20);
  }

  &:focus {
    color: $theme-primary;
    outline: 4px solid rgba($theme-primary, 0.25);
    outline-offset: -4px;
    text-decoration: none;

    i,
    svg {
      color: $body-color;
    }
  }

  &:hover {
    color: $theme-primary;
    text-decoration: none;
  }
}

button.navbar--mobile-link {
  @extend .w-100;
}

.navbar--mobile-title {
  @extend .d-block;

  @include font-size(10);

  text-transform: uppercase;
}
