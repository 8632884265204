/* ==========================================================================
Card image carousel
========================================================================== */
.card-image--carousel {
  .owl-nav {
    @extend .text-right;

    .owl-prev,
    .owl-next {
      @extend .bg-theme-primary;
      @extend .text-white;

      width: 50px;
      height: 50px;
      border-radius: 0;

      .fas {
        line-height: 50px;

        @include font-size(26);
      }
    }
  }
}
