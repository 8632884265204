/* ==========================================================================
Grid responsive spacing
========================================================================== */

.row.row-grid {
  @include media-breakpoint-down(xl) {
    [class*="col-xxl-"] + [class*="col-xxl-"] {
      margin-top: $spacer;
    }
  }

  @include media-breakpoint-down(lg) {
    [class*="col-xl-"] + [class*="col-xl-"] {
      margin-top: $spacer;
    }
  }

  @include media-breakpoint-down(md) {
    [class*="col-lg-"] + [class*="col-lg-"] {
      margin-top: $spacer;
    }
  }

  @include media-breakpoint-down(sm) {
    [class*="col-md-"] + [class*="col-md-"] {
      margin-top: $spacer;
    }
  }

  @include media-breakpoint-down(xs) {
    [class*="col-sm-"] + [class*="col-sm-"] {
      margin-top: $spacer;
    }
  }
}
