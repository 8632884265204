.img-collage {
  .small-image {
    height: 50%;
  }

  .small-image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .large-image img {
    max-height: 570px;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
