/* ==========================================================================
Variables - Must be loaded first
========================================================================== */
@import "_variables";
@import "helpers/_mixins";

/* ==========================================================================
Bootstrap - Must be loaded second
========================================================================== */
@import "~bootstrap/scss/bootstrap";

/* ==========================================================================
Bootstrap Select
========================================================================== */
@import "~bootstrap-select/sass/bootstrap-select";

/* ==========================================================================
Animate.css
========================================================================== */
@import "~animate.css/animate.min.css";

/* ==========================================================================
Animate On Scroll
========================================================================== */
@import "~aos/src/sass/aos";

/* ==========================================================================
Hamburgers
========================================================================== */
@import "~hamburgers/_sass/hamburgers/hamburgers";

/* ==========================================================================
Owl Carousel
========================================================================== */
@import "~owl.carousel/dist/assets/owl.carousel.css";
@import "~owl.carousel/dist/assets/owl.theme.default.css";

/* ==========================================================================
Slick Carousel
========================================================================== */
@import "~slick-carousel/slick/slick";
@import "~slick-carousel/slick/slick-theme";

/* ==========================================================================
Base
========================================================================== */
@import "base/_global";
@import "base/_typography";

/* ==========================================================================
Blocks
========================================================================== */
@import "blocks/_cards";
@import "blocks/_banner";
@import "blocks/_image-collage";
@import "blocks/_contact-bar";
@import "blocks/_configurator";
@import "blocks/_faq";
@import "blocks/_projects-selector";

/* ==========================================================================
Components
========================================================================== */
@import "components/_buttons";
@import "components/_certificates";
@import "components/_forms";
@import "components/_hamburgers";
@import "components/_modal";
@import "components/_page_list_plus";
@import "components/_product";
@import "components/_search-modal";
@import "components/_slider";
@import "components/_social-media";

/* ==========================================================================
Helpers
========================================================================== */
@import "helpers/_concrete5";

/* ==========================================================================
Layouts
========================================================================== */
@import "layouts/_footer";
@import "layouts/_grid";
@import "layouts/_header";
@import "layouts/_hero";
@import "layouts/_offcanvas";

/* ==========================================================================
Navbar
========================================================================== */
@import "navbar/_navbar-brand";
@import "navbar/_navbar-dropdown-menu-close";
@import "navbar/_navbar-dropdown-menu";
@import "navbar/_navbar";
@import "navbar/_navbar-mobile";
@import "navbar/_navbar-offcanvas-brand";
@import "navbar/_navbar-dropdown-filters";
@import "navbar/_navbar-offcanvas";
