.card-image-overlay-3 {
  min-height: 200px;
  max-height: 220px;

  &::before {
    background-color: rgba($white, 0%);
    border-radius: 0;
  }

  .card-img-overlay {
    bottom: 0;
    left: 0;
    position: absolute;
    top: 0;
    right: 0;

    .card-title {
      @extend .m-0;
    }

    .card-link {
      a {
        color: $theme-primary;
      }

      p {
        font-weight: 900;
      }
    }

    &:hover {
      background-color: $theme-primary;

      .card-link {
        a {
          color: $black;
        }
      }
    }
  }

  .card-footer {
    @extend .p-0;

    border: 0;
    background: transparent;
    position: absolute;
    bottom: 0;
  }
}

.project {
  .card-image-overlay-3 {
    max-height: 100%;

    .card-img-overlay {
      &:hover {
        background-color: transparent !important;
      }
    }

    @include media-breakpoint-up(sm) {
      min-height: 200px;
    }

    @include media-breakpoint-up(md) {
      min-height: 250px;
    }

    @include media-breakpoint-up(lg) {
      min-height: 300px;
    }

    @include media-breakpoint-up(xl) {
      min-height: 400px;
    }
  }
}
