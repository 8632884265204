/* ==========================================================================
Global
========================================================================== */
html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.img-responsive {
  @extend .img-fluid;
}

.branches {
  @extend .mt-1;

  min-width: 250px;
  border-radius: 0;
  padding: 0.6125rem 0;
  text-transform: uppercase;
  font-family: $font-family-2;
}

.grecaptcha-badge {
  visibility: collapse !important;
}
