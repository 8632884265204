/* ==========================================================================
Navbar dropdown menu
========================================================================== */
.navbar .dropdown-menu {
  @extend .m-0;
  @extend .pt-5;
  @extend .px-3;
  @extend .px-lg-0;
  @extend .py-lg-7;

  box-shadow: 0 1px 5px 0 rgba($black, 0.25);

  .dropdown-item {
    @extend .py-lg-0;

    .title {
      @extend .d-block;
    }

    .subtitle {
      @extend .d-none;
      @extend .d-lg-block;
      @extend .mb-lg-1;
      @extend .mb-xl-2;
      @extend .mt-lg-1;
      @extend .mt-xl-2;
    }

    &:focus {
      color: $body-color;
      outline: none;
    }
  }
}

@include media-breakpoint-down(xxl) {
  .navbar .dropdown-menu {
    background-color: $white;
    border-radius: 0;
    border: 1px solid white;
    bottom: 0;
    display: block !important;
    left: 100%;
    overflow-y: auto;
    position: fixed;
    top: 0;
    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    transition-property: left, visibility;
    visibility: hidden;
    width: 67%;
    z-index: -1;

    &.show {
      left: 0;
      visibility: visible;
    }

    .dropdown-item {
      @include font-size(18);

      color: $white;
      font-weight: 700;
      line-height: 30px;
      padding: 10px 0;
      text-transform: uppercase;

      .subtitle {
        @include font-size(16);

        display: block;
        white-space: normal;
      }

      i,
      svg {
        display: none;
      }

      &.active,
      &:focus,
      &:hover {
        background-color: transparent;
        color: $body-color;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .navbar .dropdown-menu {
    width: 100%;
    z-index: 2;

    &.show {
      left: 0;
    }
  }
}

.dropdown-items.card-columns {
  @extend
    .mb-7,
    .mb-lg-0,
    .px-0,
    .px-md-4;
}

.dropdown-item.card {
  @extend
    .mb-4;

  border: 0;
}

.dropdown-link {
  @extend
    .d-block;

  color: $body-color;
  font-weight: 400;
  text-transform: none !important;

  &:focus,
  &:hover {
    color: $theme-primary;
    text-decoration: none;
  }
}

.dropdown-toggle {
  &::after {
    @extend
      .d-none;
  }
}

.subnav-items {
  @extend
    .m-0,
    .p-0;

  list-style: none !important;
}

.subnav-link {
  @include font-size(16);

  color: $gray-500;
  font-weight: 400;
  text-transform: none !important;

  &:focus,
  &:hover {
    color: $theme-primary;
    text-decoration: none;
  }
}
