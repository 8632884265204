/* ==========================================================================
Buttons
========================================================================== */
.cust-btn {
  @extend .py-1;
  @extend .px-5;
  @extend .mb-1;
  @extend .mb-sm-0;
  @extend .mr-1;

  a {
    color: $black;
    text-decoration: none;
  }

  border-radius: 0;
  cursor: pointer;
  font-family: $font-family-1;
  font-weight: 700;
}

.primary--button {
  background-color: $theme-primary;
  color: $white;

  &:hover {
    background-color: lighten($theme-primary, 10%);
    color: $white;
  }
}

.secondary--button {
  background-color: $theme-secondary;
  color: $white;

  a {
    color: $white !important;
  }

  &:hover {
    background-color: lighten($theme-secondary, 10%);
    color: $white;
  }
}

.white--button {
  background-color: $white;

  a {
    color: $black;
  }

  &:hover {
    background-color: darken($white, 10%);
  }
}

/* ==========================================================================
Custom Block Buttons
========================================================================== */
.block--buttons {
  font-size: 0;

  .btn + .btn {
    @extend .ml-0;
    @extend .ml-sm-2;
    @extend .mt-2;
    @extend .mt-sm-0;
  }
}

@include media-breakpoint-down(xs) {
  .block--buttons {
    .btn {
      width: 100%;
    }
  }
}

/* ==========================================================================
Custom Block Buttons
========================================================================== */
.form {
  font-family: $font-family-1;
  font-weight: 300;

  .submit {
    height: 45px;
    text-transform: uppercase;

    &:hover {
      background-color: lighten($theme-primary, 10%) !important;
    }
  }
}
